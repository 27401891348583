import React from 'react';
import Link from 'next/link';

import { urls } from '@/constants';

import Container from '@/components/Container';
import Content from '@/components/Content';
import Typography from '@/components/Typography';
import Spacer from '@/components/Spacer';
import Button from '@/components/Button';

import style from './Error.sass';

interface Props {
  status: number;
  message: React.ReactNode;
}

const Error: React.FC<Props> = ({ status, message }) => (
  <div className={style.root}>
    <div className={style.content}>
      <Container>
        <Content center>
          <Typography is="h1" type="h1" block className={style.status}>
            {status}
          </Typography>

          <Spacer xs={6}>
            <Typography is="p" block className={style.message}>
              {message}
            </Typography>
          </Spacer>

          <Link href={urls.home}>
            <Button is="a" appearance="secondary" size="large">
              Get back home
            </Button>
          </Link>
        </Content>
      </Container>
    </div>
  </div>
);

export default Error;
