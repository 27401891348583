import React from 'react';

import Error from '@/components/Pages/Error';

const Error404: React.FC<{}> = () => (
  <Error
    status={404}
    message={
      <React.Fragment>
        The page you are looking for
        <br />
        cannot be found 😞
      </React.Fragment>
    }
  />
);

export default Error404;
